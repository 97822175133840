import request from '@/utils/request'

// 查询模板信息分页
export function pageTemplate(query) {
  return request({
    url: '/tl/tl-template/page',
    method: 'get',
    params: query
  })
}

// 查询模板信息详细
export function getTemplate(data) {
  return request({
    url: '/tl/tl-template/detail',
    method: 'get',
    params: data
  })
}

// 新增模板信息
export function addTemplate(data) {
  return request({
    url: '/tl/tl-template/add',
    method: 'post',
    data: data
  })
}

// 修改模板信息
export function updateTemplate(data) {
  return request({
    url: '/tl/tl-template/edit',
    method: 'post',
    data: data
  })
}

// 删除模板信息
export function delTemplate(data) {
  return request({
    url: '/tl/tl-template/delete',
    method: 'post',
    data: data
  })
}

// 编辑模板状态 模板信息
export function statusTemplate(data) {
  return request({
    url: '/tl/tl-template/status',
    method: 'post',
    data: data
  })
}

// 查询模板天信息分页
export function pageTemplateDay(query) {
  return request({
    url: '/tl/tl-template-day/page',
    method: 'get',
    params: query
  })
}

// 查询模板天信息详细
export function getTemplateDay(data) {
  return request({
    url: '/tl/tl-template-day/detail',
    method: 'get',
    params: data
  })
}

// 修改模板天信息
export function updateTemplateDay(data) {
  return request({
    url: '/tl/tl-template-day/edit',
    method: 'post',
    data: data
  })
}

// 批量修改图片信息
export function batchEditTemplateDay(data) {
  return request({
    url: '/tl/tl-template-day/batch/edit',
    method: 'post',
    data: data
  })
}

// 一键生成
export function oneTouch(data) {
  return request({
    url: '/tl/tl-template-day/one/touch',
    method: 'post',
    data: data
  })
}

// 查询模板字体信息列表
export function pageTemplateFont(query) {
  return request({
    url: '/tl/tl-template-font/page',
    method: 'get',
    params: query
  })
}

// 查询模板字体信息详细
export function getTemplateFont(data) {
  return request({
    url: '/tl/tl-template-font/detail',
    method: 'get',
    params: data
  })
}

// 修改模板字体信息
export function updateTemplateFont(data) {
  return request({
    url: '/tl/tl-template-font/edit',
    method: 'post',
    data: data
  })
}
