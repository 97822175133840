<template>
  <a-drawer width="1200" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>模版天信息</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template>
              <a-col :md="8" :sm="24">
                <a-form-item label="编码" prop="code">
                  <a-input v-model="queryParam.code" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="天数" prop="day">
                  <a-input v-model="queryParam.day" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" style="margin-right: 8px" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-right: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a-button type="dashed" style="margin-right: 8px" @click="onBtn1()">一键生成</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        size="small"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false">
        <span slot="pic" slot-scope="text, record">
          <viewer :images="[record.picPathStr]">
            <img style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="record.picPathStr">
          </viewer>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!-- <a @click="handleUpdate(record)" v-hasPermi="['tl:templateday:edit']">
            <a-icon type="edit" />修改
          </a> -->
          <!-- <a @click="handleUpdate2(record)" v-hasPermi="['tl:templateday:edit']">
            <a-icon type="edit" />修改图片
          </a> -->
          <a v-hasPermi="['tl:templateday:edit']">
            <a-upload
              action="https://pc.tlxone.com/admin-api/common/local/file/upload"
              :headers="header"
              accept=".png,.jpg,.jpeg"
              :showUploadList="false"
              @change="handleChange"
            >
              <a @click="form.id = record.id"><a-icon type="edit" />修改图片</a>
            </a-upload>
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize" />

      <a-drawer width="500px" :label-col="4" :wrapper-col="14" :visible="open1" @close="onClose1">
        <a-divider orientation="left">
          <b>编辑天信息</b>
        </a-divider>
        <a-form-model ref="form" :model="form" :rules="rules">
          <a-form-model-item label="模版名称" prop="templateName">
            <a-input v-model="form.templateName" placeholder="请输入" disabled/>
          </a-form-model-item>
          <a-form-model-item label="编码" prop="code">
            <a-input v-model="form.code" placeholder="请输入" type="number" disabled/>
          </a-form-model-item>
          <a-form-model-item label="天数" prop="day">
            <a-input v-model="form.day" placeholder="请输入" type="number" disabled/>
          </a-form-model-item>
          <a-form-model-item label="模版封面图" prop="picPath">
            <div class="ant-upload-preview" @click="$refs.upImg.edit(form.picPath)" >
              <div class="mask" :style="form.picPath ? 'opacity: 0' : 'opacity: 1'">
                <a-icon type="plus" />
              </div>
              <img :src="form.picPath" v-if="form.picPath"/>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item label="备注" prop="remark">
            <a-input v-model="form.remark" placeholder="请输入"/>
          </a-form-model-item> -->
          <div class="bottom-control">
            <a-space>
              <a-button type="primary" :loading="submitLoading" @click="submitForm">
                保存
              </a-button>
              <a-button type="dashed" @click="cancel">
                取消
              </a-button>
            </a-space>
          </div>
        </a-form-model>
      </a-drawer>
      <up-img-modal ref="upImg" :width="240" :height="400" @ok="setImg"/>
    </a-card>
  </a-drawer>
</template>

<script>
import store from '@/store'
import { pageTemplateDay, getTemplateDay, updateTemplateDay, oneTouch } from '@/api/valve/template'
import upImgModal from '@/components/upImgModal'

export default {
  name: 'User',
  props: {
  },
  components: {
    upImgModal
  },
  data() {
    return {
      header: { Authorization: 'Bearer ' + store.getters.token },
      did: null,
      open: false,
      submitLoading: false,

      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        templateId: 0,
        code: null,
        day: null,
        pageNum: 1,
        pageSize: 10
      },
      // 非多个禁用
      multiple: true,
      selectedRowKeys: [],
      selectedRows: [],
      list: [],
      columns: [
        // {
        //   title: '编码',
        //   dataIndex: 'code',
        //   width: 180,
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '日期',
          dataIndex: 'dataStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '天数',
          dataIndex: 'day',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '模板名称',
          dataIndex: 'templateName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图片路径',
          scopedSlots: { customRender: 'pic' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '添加时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center',
        //   width: 150
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      open1: false,
      // 表单参数
      form: {
        id: 0,
        picPath: '',
        remark: ''
      },
      rules: {
        picPath: [
          { required: true, message: '图片不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
    console.log('store----', store.getters)
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    // 上传成功
    handleChange (info) {
      console.log(info.file.status, info.file)
      if (info.file.status !== 'uploading') {
        this.uploadLoading = true
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info.file.status, info.file.response, info.file)
        // this.$message.success(`${info.file.name} 上传成功`)
        if (info.file.response.code == '20001') {
          this.form.picPath = info.file.response.data
          updateTemplateDay(this.form).then(response => {
            this.$message.success(
              '修改成功',
              3
            )
            this.uploadLoading = false
            this.getList()
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          this.$message.error(`上传失败 ${info.file.response.message}`)
          this.uploadLoading = false
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
        this.uploadLoading = false
      }
    },
    onBtn1() {
      oneTouch({ templateId: this.queryParam.templateId }).then(res => {
        this.$message.success(
          '成功',
          3
        )
      })
    },
    getList(id) {
      this.queryParam.templateId = id || this.queryParam.templateId
      this.open = true
      this.loading = true
      pageTemplateDay(this.queryParam).then(res => {
        this.list = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam.code = null
      this.queryParam.day = null
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onClose() {
      this.open = false
    },
    onClose1() {
      this.open1 = false
    },
    // 表单重置
    reset() {
      this.form = {
        id: 0,
        picPath: '',
        remark: ''
      }
    },
    // 取消按钮
    cancel() {
      this.open1 = false
      this.reset()
    },
    handleUpdate2(row) {
      getTemplateDay({ id: row.id }).then(response => {
        this.form = response.data
        this.$refs.upImg.edit(this.form.picPathStr)
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      this.formType = 2
      const id = row.id
      getTemplateDay({ id }).then(response => {
        this.form = response.data
        this.open1 = true
        this.formTitle = '修改'
        this.fileList = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.picPath
        }]
      })
    },
    setImg (url) {
      console.log(url)
      this.form.picPath = url

      updateTemplateDay(this.form).then(response => {
        this.$message.success(
          '修改成功',
          3
        )
        this.open1 = false
        this.getList()
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateTemplateDay(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open1 = false
              this.getList()
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
<style>
.avatar-uploader > .ant-upload {
  width: 80px;
  height: 80px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
