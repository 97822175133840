import request from '@/utils/request'

// 通用本地上传请求（单个）
export function upload (data) {
    return request({
      url: '/common/local/file/upload',
      method: 'post',
      data: data
    })
}

// 通用本地上传请求（多个）
export function uploads (data) {
    return request({
        url: '/common/local/file/uploads',
      method: 'post',
      data: data
    })
}
